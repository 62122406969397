<template>
  <div>
    <PageHeader page-name="Категории">
      <template>
        <router-link :to="{ name: 'CreateCategoriesTree' }">
          <el-button type="primary">
            Создать Категорию
          </el-button>
        </router-link>
      </template>
    </PageHeader>

    <MainPanel>
      <div class="category-header">
        <div class="category-column__item category-column__item--position-list">
          Позиция в списке
        </div>
        <div class="category-column__item category-column__item--position">
          Позиция в выдаче
        </div>
        <div class="category-column__item category-column__item--active">
          Активная
        </div>
        <div class="category-column__item category-column__item--action" />
      </div>
      <el-tree :key="refreshKey" v-loading="!categories.length" :data="categories">
        <div
          slot-scope="{ node, data }"
          class="category-node"
          :class="{'category-node--has-not-children': data.children.length === 0}"
        >
          <div class="category-node__name-wrapper">
            {{ data.name }}
          </div>


          <div class="category-node-right">
            <div class="category-column__item category-column__item--position-list">
              {{ data.position_list }}
            </div>

            <div class="category-column__item category-column__item--position">
              {{ data.position }}
            </div>

            <div class="category-column__item category-column__item--active">
              <div
                class="category-node__active-badge"
                :class="{'category-node__active-badge--active': !data.is_hidden}"
              />
            </div>


            <div class="category-column__item category-column__item--action">
              <el-button size="small" type="success" @click="onEdit(data.id)">
                <i class="far fa-edit" />
              </el-button>

              <el-button size="small" type="danger" @click="onDelete(data.id)">
                <i class="far fa-trash-alt" />
              </el-button>

              <a :href="data.url" target="_blank">
                <el-button size="small" type="info" @click="onDelete(data.id)">
                  <i class="fa fa-external-link" />
                </el-button>
              </a>
            </div>
          </div>
        </div>
      </el-tree>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel'
import { mapActions, mapGetters } from 'vuex'
import PageHeader from '@/components/common/PageHeader.vue'

export default {
  name: 'TreePage',
  components: { PageHeader, MainPanel },
  data() {
    return {
      refreshKey: 0,
      categories: [],
    }
  },
  computed: {
    ...mapGetters('wishes/categories', ['getCategoriesForSelector']),
  },
  async mounted () {
    this.refreshKey = ++this.refreshKey
    this.categories = await this.fetchCategories()
  },
  methods: {
    ...mapActions('wishes/categories', ['deleteCategory', 'fetchCategories']),
    async onEdit (id) {
      if (!id) {
        console.error('ID is undefined')
        return
      }
      this.$router.push({ name: 'UpdateCategoriesTree', params: { id } })
    },
    async onDelete (id) {
      try {
        const res = await this.$confirm('Вы уверены, что хотите удалить категорию?')
        if (res) {
          await this.deleteCategory(id)
          window.location.reload()
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/sass/_variables.sass';

.category-node {
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;

  &.category-node--has-not-children {
    cursor: default;
  }
}

::v-deep .el-tree-node__content {
  height: 46px;
}

.category-node-right {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

::v-deep .el-tree-node {
  padding: 0;
}

::v-deep .el-tree-node:not(:last-child) {
  margin: 0;
}

.el-tree {
  display: flex;
  flex-direction: column;
}

::v-deep .el-tree-node__expand-icon {
  font-size: 22px;
  color: $color-text-accent-customer;
}

.categories-tree__item {
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #409EFF;
  }

  &.is-active {
    color: #409EFF;
    font-weight: bold;
  }

  .el-icon-folder {
    margin-right: 8px;
    font-size: 16px;
  }

  .el-icon-document {
    margin-right: 8px;
    font-size: 16px;
  }

  .el-icon-arrow-right {
    margin-left: auto;
    font-size: 12px;
    transition: transform 0.3s ease;
  }

  &.is-expanded {
    .el-icon-arrow-right {
      transform: rotate(90deg);
    }
  }
}

.el-button + .el-button {
  margin-left: 0;
}

.el-button .fa {
  margin-right: 0;
}

.category-node__name-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.category-node__active-badge {
  height: 12px;
  width: 12px;
  min-width: 12px;
  border-radius: 50%;
  background: #f62e40;

  &.category-node__active-badge--active {
    background: #67C23A;
  }
}

.category-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  align-items: center;
  gap: 10px;
}

.category-column__item {
  font-weight: bold;
  font-size: 13px;
  text-align: center;

  &.category-column__item--action {
    width: 170px;
    display: flex;
    justify-content: flex-end;
    gap: 10px
  }

  &.category-column__item--active {
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.category-column__item--position {
    width: 90px;
  }

  &.category-column__item--position-list {
    width: 90px;
  }
}
</style>
