<template>
  <el-form
    ref="form"
    label-position="top"
    :model="form"
    :rules="rules"
    @submit.native.prevent="onSubmit"
  >
    <div class="row">
      <div class="col-md-6">
        <el-form-item label="Заголовок 1" prop="title_one">
          <el-input v-model="form.title_one" />
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Заголовок 1 (RU)" prop="title_one_ru">
          <el-input v-model="form.title_one_ru" />
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <el-form-item label="Содержание 1" prop="content_one">
          <Redactor v-model="form.content_one" />
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Содержание 1 (RU)" prop="content_one_ru">
          <Redactor v-model="form.content_one_ru" />
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <el-form-item label="Заголовок 2" prop="title_two">
          <el-input v-model="form.title_two" />
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Заголовок 2 (RU)" prop="title_two_ru">
          <el-input v-model="form.title_two_ru" />
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <el-form-item label="Содержание 2" prop="content_two">
          <Redactor v-model="form.content_two" />
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Содержание 2 (RU)" prop="content_two_ru">
          <Redactor v-model="form.content_two_ru" />
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <el-form-item label="Заголовок 3" prop="title_three">
          <el-input v-model="form.title_three" />
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Заголовок 3 (RU)" prop="title_three_ru">
          <el-input v-model="form.title_three_ru" />
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <el-form-item label="Содержание 3" prop="content_three">
          <Redactor v-model="form.content_three" />
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Содержание 3 (RU)" prop="content_three_ru">
          <Redactor v-model="form.content_three_ru" />
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import Redactor from '@/components/common/WysiwygRedactor'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'AboutSettingsForm',
  components: {Redactor},
  data() {
    return {
      form: {
        title_one: '',
        title_one_ru: '',
        content_one: '',
        content_one_ru: '',
        title_two: '',
        title_two_ru: '',
        content_two: '',
        content_two_ru: '',
        title_three: '',
        title_three_ru: '',
        content_three: '',
        content_three_ru: '',
      },
      rules: {
        title_one: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        title_one_ru: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        content_one: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        content_one_ru: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        title_two: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        title_two_ru: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        content_two: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        content_two_ru: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        title_three: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        title_three_ru: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        content_three: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        content_three_ru: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
      },
    }
  },
  computed: {
    ...mapState('settings', ['aboutSettings']),
  },
  created() {
    this.getSettings()
  },
  methods: {
    ...mapActions('settings', [
      'getAboutSettings',
      'updateAboutSettings',
    ]),
    async onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await this.updateAboutSettings(this.form)
          this.$message.success('Настройки успешно сохранены')
        } else {
          return false
        }
      })
    },
    async getSettings() {
      await this.getAboutSettings()
      this.fillForm(this.aboutSettings)
    },
    fillForm(model) {
      this.form = {...this.form, ...model}
    },
  },
}
</script> 