import AboutUsSettingsPage from '@/pages/settings/AboutUsSettingsPage.vue'

export default [
  {
    path: '/settings/about-us',
    name: 'AboutUsSettings',
    component: AboutUsSettingsPage,
    meta: {
      requiresAuth: true,
    },
  },
];
