import api from '@/utils/api';

export default {
  namespaced: true,
  state: () => ({
    categories: [],
    isInitialized: false,
    currentCategory: null,
  }),
  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setCurrentCategory(state, payload) {
      state.currentCategory = payload;
    },
  },
  getters: {
    getCategoriesForSelector: state => {
      const mapper = categories => categories.map(item => ({
        value: item.id,
        label: item.name,
        disabled: item.is_hidden,
        ...(item.has_children && { children: mapper(item.children) }),
      }));

      return mapper(state.categories);
    },
    getCategoriesForAdditionalSelector: state => {
      const mapper = categories => categories.map(item => ({
        value: item.id,
        label: item.name,
        ...(item.has_children && { children: mapper(item.children) }),
      }));

      return mapper(state.categories);
    },
    currentCategoryContractors: state => {
      if (!state.currentCategory || !state.currentCategory.contractors) return []
      return state.currentCategory.contractors
    },
    flatCategoryList: state => {
      if (!state.categories) return []

      const flattenCategories = (categories) => {
        return categories.reduce((acc, category) => {
          acc.push(category)
          if (category.children && category.children.length > 0) {
            acc.push(...flattenCategories(category.children))
          }
          return acc
        }, [])
      }

      return flattenCategories(state.categories)
    },
  },
  actions: {
    async fetchCategories({ commit }) {
      const response = await api.http.get('admin/categories')

      commit('setCategories', response.data.data);
      return response.data.data;
    },

    async updateImages(_, { id, formData }) {
      try {
        const res = await api.http.post(`admin/categories/${id}/image`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        return res.data;
      } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
      }
    },

    async createCategory(_, form) {
      try {
        const response = await api.http.post('admin/categories', form);
        return response.data?.data;
      } catch (error) {
        console.error('Error creating category:', error);
        throw error;
      }
    },

    async updateCategory(_, { id, form }) {
      try {
        console.log(form)
        await api.http.post(`admin/categories/${id}/update`, form);
      } catch (error) {
        console.error('Error updating category:', error);
        throw error;
      }
    },

    async deleteCategory(_, id) {
      try {
        await api.http.delete(`admin/categories/${id}/delete`);
      } catch (error) {
        console.error('Error deleting category:', error);
        throw error;
      }
    },

    async getCategoryById({commit}, id) {
      try {
        const res = await api.http.get(`admin/categories/${id}`);
        commit('setCurrentCategory', res?.data?.data);
        return res?.data?.data;
      } catch (error) {
        console.error('Error fetching category by ID:', error);
        throw error;
      }
    },
  },
};
