<template>
  <el-aside class="sidebar" width="250">
    <router-link class="logo-wrapper" :to="{name: 'Home'}">
      <img alt="Logo" class="logo" src="@/assets/img/main_logo.svg">
    </router-link>
    <el-menu>
      <router-link v-if="userCan(user, ['users.list'])" :to="{name: 'UsersList'}">
        <el-menu-item>
          <template slot="title">
            <i class="menu-item-icon fas fa-user" />
            Пользователи
          </template>
        </el-menu-item>
      </router-link>
      <router-link :to="{name: 'ClientsList'}">
        <el-menu-item>
          <template slot="title">
            <i class="menu-item-icon fas fa-user-tie" />
            Клиенты
          </template>
        </el-menu-item>
      </router-link>

      <el-submenu index="0">
        <template slot="title">
          <i class="menu-item-icon fas fa-address-book" />
          Исполнители
        </template>
        <router-link :to="{name: 'ContractorsList'}">
          <el-menu-item>
            Все
          </el-menu-item>
        </router-link>

        <router-link :to="{name: 'Tags'}">
          <el-menu-item>
            Навыки
          </el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu index="1">
        <template slot="title">
          <i class="menu-item-icon fas fa-user-plus" />
          Миньены
        </template>
        <router-link :to="{name: 'InternalUsers'}">
          <el-menu-item>
            Пользователи
          </el-menu-item>
        </router-link>

        <router-link :to="{name: 'InternalWishes'}">
          <el-menu-item>
            Тендеры
          </el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu index="2">
        <template slot="title">
          <i class="menu-item-icon fas fa-gift" />
          Тендеры
        </template>

        <router-link v-if="userCan(user, ['wishes.list'])" :to="{name: 'WishesList'}">
          <el-menu-item>
            Все
          </el-menu-item>
        </router-link>

        <router-link :to="{name: 'WishesTrash'}">
          <el-menu-item>
            Удаленные
          </el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu index="3">
        <template slot="title">
          <i class="menu-item-icon fas fa-gifts" />
          Предложения
        </template>

        <router-link v-if="userCan(user, ['offers.list'])" :to="{name: 'OffersList'}">
          <el-menu-item>
            Все
          </el-menu-item>
        </router-link>

        <router-link :to="{name: 'OffersTrash'}">
          <el-menu-item>
            Удаленные
          </el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu index="4">
        <template slot="title">
          <i class="menu-item-icon fas fa-dolly" />
          Обьявления
        </template>

        <router-link v-if="userCan(user, ['products.list'])" :to="{name: 'ProductsList'}">
          <el-menu-item>
            Все
          </el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu index="11">
        <template slot="title">
          <i class="menu-item-icon fas fa-star" />
          Афиша
        </template>

        <router-link v-if="userCan(user, ['products.list'])" :to="{name: 'AllEventsPage'}">
          <el-menu-item>
            Мероприятия
          </el-menu-item>
        </router-link>

        <router-link v-if="userCan(user, ['products.list'])" :to="{name: 'EventsArchivePage'}">
          <el-menu-item>
            Архив мероприятий
          </el-menu-item>
        </router-link>

        <router-link v-if="userCan(user, ['products.list'])" :to="{name: 'EventTypesPage'}">
          <el-menu-item>
            Типы мероприятий
          </el-menu-item>
        </router-link>
      </el-submenu>

      <router-link v-if="userCan(user, ['products.list'])" :to="{name: 'TopicsPage'}">
        <el-menu-item>
          <template slot="title">
            <i class="menu-item-icon fas fa-poo" />
            Тематические страницы
          </template>
        </el-menu-item>
      </router-link>

      <router-link :to="{name:'StatisticsList'}">
        <el-menu-item>
          <template slot="title">
            <i class="menu-item-icon fas fa-chart-line" />
            Статистика
          </template>
        </el-menu-item>
      </router-link>

      <router-link v-if="userCan(user, ['complaints.list'])" :to="{name: 'ComplaintsList'}">
        <el-menu-item>
          <template slot="title">
            <i class="menu-item-icon fas fa-frown" />
            Жалобы
          </template>
        </el-menu-item>
      </router-link>

      <router-link v-if="userCan(user, ['admin-users.list'])" :to="{name: 'AdminsList'}">
        <el-menu-item>
          <template slot="title">
            <i class="menu-item-icon fas fa-user-tie" />
            Администраторы
          </template>
        </el-menu-item>
      </router-link>

      <el-submenu v-if="userCan(user, ['helpcenter.list', 'helpcenter.manage'])" index="5">
        <template slot="title">
          <i class="menu-item-icon far fa-life-ring" />
          Центр помощи
        </template>

        <router-link v-if="userCan(user, ['helpcenter.list'])" :to="{name: 'FAQList'}">
          <el-menu-item>FAQ</el-menu-item>
        </router-link>

        <router-link v-if="userCan(user, ['helpcenter.manage'])" :to="{name: 'TicketCategoriesList'}">
          <el-menu-item>Категории тикетов</el-menu-item>
        </router-link>
        <router-link :to="{name: 'TicketList'}">
          <el-menu-item>Тикеты</el-menu-item>
        </router-link>
        <router-link :to="{name: 'problemTicketList'}">
          <el-menu-item>Проблемные Тикеты</el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu index="6">
        <template slot="title">
          <i class="menu-item-icon fas fa-gamepad" />
          Управление
        </template>

        <router-link :to="{name: 'CategoriesTree'}">
          <el-menu-item>
            <template slot="title">
              <i class="menu-item-icon fas fa-sitemap" />
              Категории
            </template>
          </el-menu-item>
        </router-link>

        <router-link :to="{name: 'GeoList'}">
          <el-menu-item>
            <template slot="title">
              <i class="menu-item-icon fas fa-building" />
              Города
            </template>
          </el-menu-item>
        </router-link>

        <router-link :to="{name: 'EventsMainList'}">
          <el-menu-item>
            <template slot="title">
              <i class="menu-item-icon fas fa-star" />
              События
            </template>
          </el-menu-item>
        </router-link>

        <router-link :to="{name: 'AboutUsSettings'}">
          <el-menu-item>
            <template slot="title">
              <i class="menu-item-icon fas fa-users" />
              О нас
            </template>
          </el-menu-item>
        </router-link>

        <router-link :to="{name: 'SocialsList'}">
          <el-menu-item>
            <template slot="title">
              <i class="menu-item-icon fas fa-thumbs-up" />
              Социальные сети
            </template>
          </el-menu-item>
        </router-link>

        <router-link :to="{name: 'MessengersList'}">
          <el-menu-item>
            <template slot="title">
              <i class="menu-item-icon fas fa-comments" />
              Мессенджеры
            </template>
          </el-menu-item>
        </router-link>
      </el-submenu>

      <el-submenu v-if="userCan(user, ['translations.edit', 'translations.manage'])" index="7">
        <template slot="title">
          <i class="menu-item-icon fas fa-globe" />
          Переводы
        </template>

        <el-submenu v-if="backEndCategories.length && userCan(user, ['translations.edit'])" index="6-1">
          <template slot="title">
            <div class="sub-menu-dropdown">
              Back End
            </div>
          </template>
          <router-link
            v-for="category in backEndCategories"
            :key="category.name + category.target"
            :to="{name: 'Translations', params: {id: category.id, category: category.name, target: category.target}}"
          >
            <el-menu-item>
              {{ category.name }}
            </el-menu-item>
          </router-link>
        </el-submenu>

        <el-submenu v-if="frontEndCategories.length && userCan(user, ['translations.edit'])" index="4-2">
          <template slot="title">
            <div class="sub-menu-dropdown">
              Front End
            </div>
          </template>
          <router-link
            v-for="category in frontEndCategories"
            :key="category.name + category.target"
            :to="{name: 'Translations', params: {id: category.id, category: category.name, target: category.target}}"
          >
            <el-menu-item>
              {{ category.name }}
            </el-menu-item>
          </router-link>
        </el-submenu>

        <el-submenu v-if="helpCenterCategories.length && userCan(user, ['translations.edit'])" index="4-3">
          <template slot="title">
            <div class="sub-menu-dropdown">
              Help Center
            </div>
          </template>
          <router-link
            v-for="category in helpCenterCategories"
            :key="category.name + category.target"
            :to="{name: 'Translations', params: {id: category.id, category: category.name, target: category.target}}"
          >
            <el-menu-item>
              {{ category.name }}
            </el-menu-item>
          </router-link>
        </el-submenu>
        <router-link v-if="userCan(user, ['translations.manage'])" :to="{name: 'ImportTranslationFiles'}">
          <el-menu-item>Управлениe переводами</el-menu-item>
        </router-link>
      </el-submenu>
    </el-menu>
    <el-menu>
      <router-link v-if="userCan(user, ['search.view'])" :to="{name: 'SearchManagement'}">
        <el-menu-item>
          <template slot="title">
            <i class="menu-item-icon fas fa-search" />
            Управления поиском
          </template>
        </el-menu-item>
      </router-link>
    </el-menu>
  </el-aside>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {userCan} from '@/utils/permissions'

export default {
  name: 'TheSidebar',
  computed: {
    ...mapState('translations', [
      'translationsCategories',
    ]),
    ...mapState('auth', [
      'user',
    ]),
    frontEndCategories() {
      return this.translationsCategories?.filter(x => x.target === 'FRONTEND') ?? []
    },
    backEndCategories() {
      return this.translationsCategories?.filter(x => x.target === 'BACKEND') ?? []
    },
    helpCenterCategories() {
      return this.translationsCategories?.filter(x => x.target === 'HELP') ?? []
    },
  },
  created() {
    this.fetchTranslationsCategories()
  },
  methods: {
    ...mapActions('translations', [
      'fetchTranslationsCategories',
    ]),
    userCan,
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.sidebar
  min-height: 100vh
  width: 250px
  background: $color-sidebar

::v-deep
  .el-menu
    background-color: transparent
    border: none

    a
      display: block

      &:hover
        text-decoration: none

    .router-link-active
      background: $color-accent-foreground

  .el-submenu
    .el-menu-item
      min-height: 40px
      height: auto
      line-height: 1.2
      font-size: 13px

  .el-submenu__title, .el-menu-item
    color: #FFFFFF
    min-height: 40px
    height: auto
    line-height: 1.2
    display: flex
    align-items: center
    font-size: 15px

    &:hover, &:active, &:focus
      background: $color-accent-foreground

  .el-menu-item.is-active
    color: #ffffff

    .menu-item-icon
      color: $color-text-accent-customer

.menu-item-icon
  color: $color-text-accent-customer
  font-size: 20px
  margin-right: 10px

.logo-wrapper
  margin: 20px 20px 25px 20px
  display: block

.sub-menu-dropdown
  font-size: 13px

</style>
