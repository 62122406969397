import ListPage from '@/pages/messengers/ListPage'
import CreateUpdatePage from '@/pages/messengers/CreateUpdatePage'

export default [
  {
    path: '/messengers/list',
    name: 'MessengersList',
    component: ListPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/messengers/create',
    name: 'CreateMessenger',
    component: CreateUpdatePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/messengers/update/:id',
    name: 'UpdateMessenger',
    component: CreateUpdatePage,
    meta: {
      requiresAuth: true,
    },
  },
] 