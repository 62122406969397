<template>
  <el-table :data="list" stripe style="width: 100%">
    <el-table-column label="Изображение" width="120">
      <template slot-scope="scope">
        <img
          v-if="scope.row.image"
          alt="Social icon"
          class="socials-list__image"
          :src="scope.row.image"
        >
        <span v-else>—</span>
      </template>
    </el-table-column>
    <el-table-column label="Название" prop="title" width="200" />
    <el-table-column label="Ссылка" min-width="300" prop="link" />
    <el-table-column fixed="right" width="120">
      <template slot-scope="scope">
        <el-button
          size="small"
          type="success"
          @click="onEdit(scope.row.id)"
        >
          <i class="far fa-edit" />
        </el-button>

        <el-button
          size="small"
          type="danger"
          @click="onDelete(scope.row.id)"
        >
          <i class="far fa-trash-alt" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'List',
  computed: {
    ...mapState('socials', {
      list: 'socialsList',
    }),
  },
  methods: {
    ...mapActions('socials', [
      'deleteSocial',
      'fetchSocialsList',
    ]),
    onEdit(id) {
      this.$router.push({name: 'UpdateSocial', params: {id}})
    },
    async onDelete(id) {
      try {
        const res = await this.$confirm('Вы действительно хотите удалить эту социальную сеть?')
        if (res) {
          await this.deleteSocial(id)
          await this.fetchSocialsList()
        }
      } catch {
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.socials-list__image
  max-width: 50px
  max-height: 50px
  object-fit: contain
</style>
