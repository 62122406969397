<template>
  <div>
    <div class="title-bar">
      <h1>{{ isUpdate ? 'Редактировать мессенджер' : 'Создать мессенджер' }}</h1>

      <div class="title-bar__button-wrapper">
        <router-link class="button-wrapper-link" :to="{name: 'MessengersList'}">
          <el-button type="info">
            <i class="fas fa-list" /> Список
          </el-button>
        </router-link>

        <el-button type="primary" @click="$refs.form.onSubmit()">
          Сохранить
        </el-button>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <Form ref="form" />
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/components/messengers/Form'

export default {
  name: 'CreateUpdateMessengerPage',
  metaInfo: {
    title: 'Создать/Редактировать мессенджер',
  },
  components: {Form},
  computed: {
    isUpdate() {
      return this.$route.name === 'UpdateMessenger'
    },
  },
}
</script>

<style lang="sass" scoped>
.messengers-form
  padding: 20px

.messengers-form__form
  max-width: 600px
  margin: 0 auto

.messengers-form__upload-image
  margin-bottom: 10px

.messengers-form__preview
  max-width: 200px
  max-height: 200px
  object-fit: contain
</style> 