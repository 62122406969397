<template>
  <div>
    <div class="title-bar">
      <h1>Настройки страницы О нас</h1>

      <div class="title-bar__button-wrapper">
        <el-button type="primary" @click="$refs.form.onSubmit()">
          Сохранить
        </el-button>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <Form ref="form" />
      </div>
    </div>
  </div>
</template>


<script>
import Form from '@/components/settings/about/Form'

export default {
  name: 'AboutUsSettingsPage',
  metaInfo: {
    title: 'Настройки страницы О нас',
  },
  components: {Form},
}

</script>


<style scoped lang="sass">

</style>
