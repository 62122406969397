<template>
  <el-form
    ref="form"
    label-position="top"
    :model="form"
    :rules="rules"
    @submit.native.prevent="onSubmit"
  >
    <div class="row">
      <div class="col-md-6">
        <el-form-item label="Название" prop="title">
          <el-input v-model="form.title" />
          {{ $_ErrorMixin_getError('title') }}
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item label="Ссылка" prop="link">
          <el-input v-model="form.link" />
          {{ $_ErrorMixin_getError('link') }}
        </el-form-item>
      </div>
      <div class="col-md-12">
        <el-form-item label="Изображение" prop="image">
          <SingleImageEdit
            :image="imageObj"
            @status-change="addImage"
          />
          {{ $_ErrorMixin_getError('image') }}
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import { mapActions } from 'vuex'
import ErrorMixin from '@/utils/ErrorMixin'
import SingleImageEdit from '@/components/common/SingleImageEdit.vue'

const defaultValues = {
  title: '',
  link: '',
  image: null,
}

export default {
  name: 'SocialForm',
  components: { SingleImageEdit },
  mixins: [ErrorMixin],
  data() {
    return {
      form: { ...defaultValues },
      rules: {
        title: [
          { required: true, message: 'Пожалуйста, введите название', trigger: 'blur' },
        ],
        link: [
          { required: true, message: 'Пожалуйста, введите ссылку', trigger: 'blur' },
        ],
        image: [
          { required: true, message: 'Пожалуйста, загрузите иконку', trigger: 'blur' },
        ],
      },
      editMode: false,
      imageObj: null,
    }
  },
  computed: {
    isUpdate() {
      return this.$route.name === 'UpdateSocial'
    },
  },
  created() {
    this.fetchSocial()
  },
  methods: {
    ...mapActions('socials', [
      'createSocial',
      'updateSocial',
      'getSocial',
      'uploadImage',
    ]),
    async onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            if (this.isUpdate) {
              await this.updateSocial(this.form)
              this.$message.success('Социальная сеть успешно обновлена')
            } else {
              await this.createSocial(this.form)
              this.$message.success('Социальная сеть успешно создана')
            }

            this.$_ErrorMixin_clearErrors()
            this.$router.push({ name: 'SocialsList' })
          } catch (error) {
            this.$_ErrorMixin_handleError(error)
          }
        }
      })
    },
    addImage(image) {
      this.imageObj = image
      this.form.image = image ? image.url : null
    },
    async fetchSocial() {
      const id = this.$route.params.id
      if (id) {
        const model = await this.getSocial(id)
        this.fillForm(model)

        this.imageObj = {id: 1, url: model.image, name: 'eee'}
      }
    },
    fillForm(model) {
      this.form = {
        ...this.form,
        ...model,
        image: model.image,
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.socials-form__upload-image
  margin-bottom: 10px

.socials-form__preview
  margin-top: 10px
  padding: 10px
  border: 1px solid #dcdfe6
  border-radius: 4px
  display: inline-block

.socials-form__preview-image
  max-width: 200px
  max-height: 200px
  object-fit: contain
</style>
