<template>
  <el-form
    ref="form"
    label-position="top"
    :model="form"
    :rules="rules"
    @submit.native.prevent="onSubmit"
  >
    <div class="row">
      <div class="col-md-8">
        <el-form-item label="Вопрос" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
      </div>
      <div class="col-md-4">
        <el-form-item label="Группа" prop="group">
          <el-select v-model="form.group" placeholder="Выберите группу" style="width: 100%">
            <el-option label="На главной" value="main" />
            <el-option label="На объявлениях" value="product" />
            <el-option label="На исполнителях" value="contractor" />
          </el-select>
        </el-form-item>
      </div>
    </div>

    <el-form-item label="Ответ" prop="answer">
      <Redactor v-model="form.answer" />
    </el-form-item>
  </el-form>
</template>

<script>
import Redactor from '@/components/common/WysiwygRedactor'
import {mapActions} from 'vuex'

export default {
  name: 'FAQForm',
  components: {Redactor},
  data() {
    return {
      form: {
        name: '',
        text: '',
        group: 'main',
      },
      rules: {
        name: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        answer: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'},
        ],
        group: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'change'},
        ],
      },
    }
  },
  computed: {
    isUpdate() {
      return this.$route.name === 'UpdateFAQ'
    },
  },
  created() {
    this.getParamsFromRouter()
  },
  methods: {
    ...mapActions('help-center', [
      'getFAQItem',
      'createFAQItem',
      'updateFAQItem',
    ]),
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.isUpdate) {
            await this.updateFAQItem(this.form)
          } else {
            await this.createFAQItem(this.form)
          }
          await this.$router.push({name: 'FAQList'})
        } else {
          return false
        }
      })
    },
    async getParamsFromRouter() {
      const id = this.$route.params.id
      if (id) {
        const model = await this.getFAQItem(id)
        this.fillForm(model)
      }
    },
    fillForm(model) {
      this.form = {...this.form, ...model}
    },
  },
}
</script>
