<template>
  <div class="editable-text">
    <div v-if="editing">
      <el-input
        v-bind="$attrs"
        :value="value"
        @input="(e) => $emit('input', e)"
      />
    </div>
    <div v-else>
      <i class="editable-text__icon far fa-edit" @click="editing = true" />
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditableText',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editing: false,
    }
  },
  methods: {
    resetEditing() {
      this.editing = false;
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"


.editable-text__icon
  margin-right: 10px
  color: $color-text-accent-customer
  cursor: pointer

  &:hover
    color: #555555

.editable-text
  word-break: break-word

</style>
