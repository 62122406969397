<template>
  <div class="single-image-edit">
    <el-upload
      ref="imageUpload"
      action=""
      :class="{'image-edit--image-uploaded': editableImages.length > 0 || uploadInProcess}"
      :file-list="editableImages"
      :http-request="onFileSelect"
      :limit="1"
      list-type="picture-card"
      :on-remove="handleRemoveImage"
    >
      <i class="el-icon-plus" />
    </el-upload>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'SingleImageEdit',
  props: {
    image: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: null,
      editableImages: [],
      selectedFile: null,
      uploadInProcess: false,
    }
  },
  watch: {
    image(newValue) {
      if (newValue) {
        this.prepareImageEditing();
      }
    },
  },
  methods: {
    ...mapActions('contractors', ['uploadImage']),
    prepareImageEditing() {
      if (!this.image) return [];

      this.editableImages = [{
        name: this.image.name,
        url: this.image?.url || this.image.path,
        id: this.image.id,
      }]
    },

    async onFileSelect(res) {
      this.uploadInProcess = true
      try {
        const file = res.file;
        const formData = new FormData();
        formData.append('file', file, file.name);

        const img = await this.uploadImage(formData);
        const fileToAdd = {
          name: file.name,
          url: img.path,
          id: img.id,
        };

        this.editableImages = [fileToAdd];
        this.updateImagesStatus();
      } catch (error) {
        this.$message.error('Ошибка загрузки изображения');
      } finally {
        this.uploadInProcess = false
      }
    },
    handleRemoveImage() {
      this.editableImages = [];
      this.updateImagesStatus();
    },
    updateImagesStatus() {
      this.$emit('status-change', this.editableImages.length ? this.editableImages[0] : null);
    },
  },
}
</script>

<style lang="sass" scoped>
.image-edit--image-uploaded
  ::v-deep
    .el-upload--picture-card
      display: none


::v-deep
  .el-upload-list__item
    transition: none !important
  .el-upload-list__item-actions
    transition: none !important
  .el-upload-list__item-thumbnail
    transition: none !important
  .el-upload-list__item-status-label
    transition: none !important
  .el-upload-list__item-name
    transition: none !important
  .el-upload-list__item-delete
    transition: none !important
  .el-upload-list--picture-card
    .el-upload-list__item
      width: auto

</style>
