<template>
  <el-form
    ref="form"
    label-position="top"
    :model="form"
    :rules="rules"
    @submit.native.prevent="onSubmit"
  >
    <div class="row">
      <div class="col-md-6">
        <el-form-item label="Название" prop="title">
          <el-input v-model="form.title" />
          {{ $_ErrorMixin_getError('title') }}
        </el-form-item>

        <el-form-item label="Название (RU)" prop="title_ru">
          <el-input v-model="form.title_ru" />
          {{ $_ErrorMixin_getError('title_ru') }}
        </el-form-item>

        <el-form-item label="Позиция в меню" prop="position">
          <el-input-number v-model="form.position" :min="0" />
          {{ $_ErrorMixin_getError('position') }}
        </el-form-item>

        <el-form-item label="Позиция в выдаче" prop="position_list">
          <el-input-number v-model="form.position_list" :min="0" />
          {{ $_ErrorMixin_getError('position_list') }}
        </el-form-item>

        <el-form-item label="Родительская категория" prop="parent_id">
          <el-select
            v-model="form.parent_id"
            clearable
            filterable
            placeholder="Выберите категорию"
          >
            <el-option
              v-for="item in [{id: null, name: 'Нет родительской категории'}, ...flatCategoryList]"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          {{ $_ErrorMixin_getError('parent_id') }}
        </el-form-item>

        <el-form-item label="Slug" prop="slug">
          <el-input v-model="form.slug" />
          {{ $_ErrorMixin_getError('slug') }}
        </el-form-item>

        <el-form-item label="Slug SEO" prop="slug_seo">
          <el-input v-model="form.slug_seo" />
          {{ $_ErrorMixin_getError('slug_seo') }}
        </el-form-item>
      </div>

      <div class="col-md-6">
        <el-form-item label="SEO Title" prop="seo_title">
          <el-input v-model="form.seo_title" />
          {{ $_ErrorMixin_getError('seo_title') }}
        </el-form-item>

        <el-form-item label="SEO Title (RU)" prop="seo_title_ru">
          <el-input v-model="form.seo_title_ru" />
          {{ $_ErrorMixin_getError('seo_title_ru') }}
        </el-form-item>

        <el-form-item>
          <el-switch
            v-model="form.is_hidden"
            active-color="#67C23A"
            :active-value="false"
            inactive-color="#f62e40"
            :inactive-value="true"
          />
          <span class="switch-label">Активная</span>
        </el-form-item>

        <el-form-item label="Специалисты для блока &quot;Послуги за категоріями&quot;" prop="contractors">
          <el-select
            v-model="form.contractors"
            filterable
            :loading="loading"
            multiple
            :no-data-text="'Нет данных'"
            placeholder="Введите как минимум 3 символа для поиска"
            remote
            :remote-method="searchContractorsHandler"
            reserve-keyword
          >
            <el-option
              v-for="item in [...contractors, ...currentCategoryContractors]"
              :key="item.id"
              :label="item.username"
              :value="item.id"
            />
          </el-select>
          {{ $_ErrorMixin_getError('contractors') }}
        </el-form-item>


        <el-form-item>
          <el-switch v-model="form.is_event_categories" />
          <span class="switch-label">Выводить на главной в блоке  "Послуги за категоріями"</span>
        </el-form-item>
      </div>
    </div>
  </el-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ErrorMixin from '@/utils/ErrorMixin'

const defaultValues = {
  title: '',
  title_ru: '',
  position: 0,
  position_list: 0,
  parent_id: null,
  slug: '',
  slug_seo: '',
  seo_title: '',
  seo_title_ru: '',
  is_main: false,
  is_event_categories: false,
  is_hidden: false,
  contractors: [],
}

export default {
  name: 'TreeForm',
  mixins: [ErrorMixin],
  data() {
    return {
      form: { ...defaultValues },
      loading: false,
      contractors: [],
      rules: {
        title: [
          { required: true, message: 'Пожалуйста, введите название', trigger: 'blur' },
        ],
        title_ru: [
          { required: true, message: 'Пожалуйста, введите название на русском', trigger: 'blur' },
        ],
        position: [
          { required: true, message: 'Пожалуйста, укажите позицию', trigger: 'blur' },
          { type: 'number', message: 'Позиция должна быть числом', trigger: 'blur' },
        ],
        position_list: [
          { required: true, message: 'Пожалуйста, укажите позицию в списке', trigger: 'blur' },
          { type: 'number', message: 'Позиция в списке должна быть числом', trigger: 'blur' },
        ],
        slug: [
          { required: true, message: 'Пожалуйста, введите slug', trigger: 'blur' },
          { pattern: /^[a-z0-9-]+$/, message: 'Slug может содержать только строчные буквы, цифры и дефисы', trigger: 'blur' },
        ],
        slug_seo: [
          { required: true, message: 'Пожалуйста, введите SEO slug', trigger: 'blur' },
          { pattern: /^[a-z0-9-]+$/, message: 'SEO slug может содержать только строчные буквы, цифры и дефисы', trigger: 'blur' },
        ],
        seo_title: [
          { required: true, message: 'Пожалуйста, введите SEO title', trigger: 'blur' },
        ],
        seo_title_ru: [
          { required: true, message: 'Пожалуйста, введите SEO title на русском', trigger: 'blur' },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('wishes/categories', ['getCategoriesForSelector', 'currentCategoryContractors', 'flatCategoryList']),
    ...mapState('wishes/categories', ['categories']),
    ...mapState('eventsMain', ['contractorsList']),
    isUpdate() {
      return this.$route.name === 'UpdateCategoriesTree'
    },
  },
  created() {
    this.getParamsFromRouter()
    this.fetchCategories()
  },
  methods: {
    ...mapActions('wishes/categories', ['createCategory', 'updateCategory', 'getCategoryById', 'fetchCategories']),
    ...mapActions('eventsMain', ['searchContractors']),
    async onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            if (this.isUpdate) {
              await this.updateCategory( { id: this.$route.params.id, form: this.form })
              this.$message.success('Категория успешно обновлена')
            } else {
              await this.createCategory(this.form)
              this.$message.success('Категория успешно создана')
            }

            this.$_ErrorMixin_clearErrors()
            this.$router.push({ name: 'CategoriesTree' })
          } catch (error) {
            this.$_ErrorMixin_handleError(error)
          }
        }
      })
    },
    async searchContractorsHandler(query) {
      if (query !== '') {
        if (query.length < 3) return
        this.loading = true
        try {
          await this.searchContractors(query)
          this.contractors = this.contractorsList
        } catch (error) {
          console.error('Error searching contractors:', error)
        } finally {
          this.loading = false
        }
      } else {
        this.contractors = []
      }
    },
    async getParamsFromRouter() {
      const id = this.$route.params.id
      if (id) {
        const model = await this.getCategoryById(id)
        this.fillForm(model)
      }
    },
    fillForm(model) {
      this.form = {
        ...this.form,
        ...model,
        contractors: model.contractors.map(i => i.id),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}

.switch-label {
  margin-left: 10px;
  vertical-align: middle;
}

.el-switch {
  vertical-align: middle;
}
</style>
