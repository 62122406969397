<template>
  <div>
    <div class="title-bar">
      <h1>{{ isUpdate ? 'Редактировать социальную сеть' : 'Создать социальную сеть' }}</h1>

      <div class="title-bar__button-wrapper">
        <router-link class="button-wrapper-link" :to="{name: 'SocialsList'}">
          <el-button type="info">
            <i class="fas fa-list" /> Список
          </el-button>
        </router-link>

        <el-button type="primary" @click="$refs.form.onSubmit()">
          Сохранить
        </el-button>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <Form ref="form" />
      </div>
    </div>
  </div>
</template>

<script>
import Form from '@/components/socials/Form'

export default {
  name: 'CreateUpdateSocialPage',
  metaInfo: {
    title: 'Создать/Редактировать социальную сеть',
  },
  components: {Form},
  computed: {
    isUpdate() {
      return this.$route.name === 'UpdateSocial'
    },
  },
}
</script>

<style lang="sass" scoped>
.socials-form
  padding: 20px

.socials-form__form
  max-width: 600px
  margin: 0 auto

.socials-form__upload-image
  margin-bottom: 10px

.socials-form__preview
  max-width: 200px
  max-height: 200px
  object-fit: contain
</style>
