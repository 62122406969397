<template>
  <div class="internal-user-to-real">
    <el-form class="internal-user-to-real__form">
      <h3 class="internal-user-to-real__heading">Оживить миньена</h3>

      <el-checkbox v-model="makeRealCheckBox">Передать акаунт Юзеру</el-checkbox>

      <el-form-item v-if="makeRealCheckBox" label="Телефон Юзера ">
        <el-input v-model="phone" :disabled="!makeRealCheckBox" />
        {{ $_ErrorMixin_getError('phone') }}
      </el-form-item>
    </el-form>
    <el-button
      ref="phone"
      :disabled="!makeRealCheckBox"
      type="success"
      @click="makeRealUser"
    >
      Оживить юзера!
    </el-button>
  </div>
</template>

<script>
import ErrorMixin from '@/utils/ErrorMixin';
import {mapActions} from 'vuex';

export default {
  name: 'InternalUserToRealForm',
  mixins: [ErrorMixin],
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
data() {
  return {
    makeRealCheckBox: false,
    phone: '',
  }
},
  methods: {
    ...mapActions('internalUsers', ['internalUserToReal']),
    async makeRealUser() {
      try {
        await this.internalUserToReal({userId: this.userId, phone: this.phone} )
        this.$message({
          message: 'Оно ЖИВОЕ!!!',
          type: 'info',
        })
        this.$emit('closeDrawerEmit')
        this.makeRealCheckBox = false
        this.phone = ''
      } catch (e) {
        this.$_ErrorMixin_handleError(e)
        await this.$message.error('Ошибка!');
      }
    },
  },
}
</script>

<style scoped>
.internal-user-to-real {
  margin-top: 50px;
}

.internal-user-to-real__heading {
  color: #555555;
  margin-bottom: 15px;
}

.internal-user-to-real__form {
  margin-bottom: 20px;
}
</style>
