<template>
  <div class="contractors-services">
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Услуги исполнителя</h2>
        <div>
          <el-button
            style="margin-bottom: 10px;"
            type="primary"
            @click="toggleEditServiceMode"
          >
            {{ isEditServicesMode ? 'Заблокувати' : 'Редагувати' }}
          </el-button>
          <el-button
            v-if="isEditedService"
            :disabled="canSaveServices"
            style="margin-left: 10px; margin-bottom: 10px;"
            type="success"
            @click="saveServices"
          >
            Зберегти
          </el-button>
          <el-button
            :disabled="!isEditServicesMode"
            style="margin-left: 10px; margin-bottom: 10px;"
            @click="addServicesRow"
          >
            Додати
          </el-button>
          <el-table
            border
            :data="contractorsServices"
            style="width: 100%"
          >
            <!-- Название -->
            <el-table-column label="Название" prop="title" width="200">
              <template #default="scope">
                <el-input
                  v-model="scope.row.title"
                  :disabled="!isEditServicesMode"
                  placeholder="Введите название"
                  size="small"
                  @input="markAsEditedService"
                />
              </template>
            </el-table-column>
            <!-- Описание -->
            <el-table-column label="Описание" prop="description">
              <template #default="scope">
                <Redactor
                  v-model="scope.row.description"
                  class="editable-text__editor"
                  :disabled="!isEditServicesMode"
                  variant="without-headings"
                  @input="markAsEditedService"
                />
              </template>
            </el-table-column>
            <!-- Цена -->
            <el-table-column label="Цена" prop="price" width="120">
              <template #default="scope">
                <el-input
                  v-model="scope.row.price"
                  :disabled="!isEditServicesMode || scope.row.is_negotiable"
                  :min="0"
                  size="small"
                  @input="markAsEditedService"
                />
              </template>
            </el-table-column>
            <!-- Валюта -->
            <el-table-column label="Валюта" prop="currency" width="120">
              <template #default="scope">
                <el-select
                  v-model="scope.row.currency"
                  :disabled="!isEditServicesMode || scope.row.is_negotiable"
                  size="small"
                  @change="markAsEditedService"
                >
                  <el-option label="USD" value="USD" />
                  <el-option label="UAH" value="UAH" />
                  <el-option label="EUR" value="EUR" />
                </el-select>
              </template>
            </el-table-column>
            <!-- Цена "от" -->
            <el-table-column label="Цена от" prop="is_price_from" width="100">
              <template #default="scope">
                <el-checkbox
                  v-model="scope.row.is_price_from"
                  :disabled="!isEditServicesMode || scope.row.is_negotiable"
                  @change="markAsEditedService"
                >
                  От
                </el-checkbox>
              </template>
            </el-table-column>
            <!-- Цена договорная -->
            <el-table-column label="Цена договорная" prop="is_negotiable" width="160">
              <template #default="scope">
                <el-checkbox
                  v-model="scope.row.is_negotiable"
                  :disabled="!isEditServicesMode"
                  @change="isNegotiableChanged(scope.row)"
                >
                  Договорная
                </el-checkbox>
              </template>
            </el-table-column>
            <!-- Действия -->
            <el-table-column label="Действия" width="120">
              <template #default="scope">
                <el-button
                  :disabled="!isEditServicesMode"
                  size="small"
                  type="danger"
                  @click="removeServicesRow(scope.$index)"
                >
                  Удалить
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </MainPanel>
    </div>
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Портфолио</h2>
        <el-button
          style="margin-bottom: 10px;"
          type="primary"
          @click="toggleEditPortfolioMode"
        >
          {{ isEditPortfolioMode ? 'Заблокувати' : 'Редагувати' }}
        </el-button>
        <el-button
          v-if="isEditedPortfolio"
          :disabled="portfolioSaveDisabled"
          style="margin-left: 10px; margin-bottom: 10px;"
          type="success"
          @click="savePortfolio"
        >
          Зберегти
        </el-button>
        <div class="comparing-table__label">
          Изображения
          <ImagesEdit
            :edit-mode="isEditPortfolioMode"
            :images="portfolioImages"
            @save-disabled="disablePortfolioSave"
            @status-change="handleImagesStatusChange"
          />
        </div>

        <div class="comparing-table__label">
          Ссылки на видео
          <PortfolioVideos
            :edit-mode="isEditPortfolioMode"
            :videos="portfolioVideos"
            @status-change="handleVideosStatusChange"
          />
        </div>
      </MainPanel>
    </div>
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Примеры работ</h2>
        <el-button
          style="margin-bottom: 10px;"
          type="primary"
          @click="toggleEditExamplesMode"
        >
          {{ isEditExamplesMode ? 'Заблокувати' : 'Редагувати' }}
        </el-button>
        <el-button
          v-if="isEditedExample"
          :disabled="canSaveExamples"
          style="margin-left: 10px; margin-bottom: 10px;"
          type="success"
          @click="saveExamples"
        >
          Зберегти
        </el-button>
        <el-button
          :disabled="!isEditExamplesMode"
          style="margin-left: 10px; margin-bottom: 10px;"
          @click="addExamplesRow"
        >
          Додати
        </el-button>
        <el-table
          :data="workExamples"
          style="width: 100%"
        >
          <el-table-column label="Название" prop="title" width="200">
            <template #default="scope">
              <el-input
                v-model="scope.row.title"
                :disabled="!isEditExamplesMode"
                placeholder="Введите название"
                size="small"
                @input="markAsEditedExample"
              />
            </template>
          </el-table-column>
          <el-table-column label="Цена" prop="price" width="120">
            <template #default="scope">
              <el-input
                v-model="scope.row.price"
                :disabled="!isEditExamplesMode || scope.row.is_negotiable"
                :min="0"
                size="small"
                @input="markAsEditedExample"
              />
            </template>
          </el-table-column>
          <!-- Валюта -->
          <el-table-column label="Валюта" prop="currency" width="120">
            <template #default="scope">
              <el-select
                v-model="scope.row.currency"
                :disabled="!isEditExamplesMode || scope.row.is_negotiable"
                size="small"
                @change="markAsEditedExample"
              >
                <el-option label="USD" value="USD" />
                <el-option label="UAH" value="UAH" />
                <el-option label="EUR" value="EUR" />
              </el-select>
            </template>
          </el-table-column>
          <!-- Цена "от" -->
          <el-table-column label="Цена от" prop="is_price_from" width="100">
            <template #default="scope">
              <el-checkbox
                v-model="scope.row.is_price_from"
                :disabled="!isEditExamplesMode || scope.row.is_negotiable"
                @change="markAsEditedExample"
              >
                От
              </el-checkbox>
            </template>
          </el-table-column>
          <!-- Цена договорная -->
          <el-table-column label="Цена договорная" prop="is_negotiable" width="160">
            <template #default="scope">
              <el-checkbox
                v-model="scope.row.is_negotiable"
                :disabled="!isEditExamplesMode"
                @change="isNegotiableChanged(scope.row)"
              >
                Договорная
              </el-checkbox>
            </template>
          </el-table-column>

          <el-table-column
            label="Изображения"
            prop="work_example_images"
          >
            <template slot-scope="scope">
              <ImagesEdit
                :edit-mode="isEditExamplesMode"
                :images="scope.row.work_example_images"
                :index="scope.$index"
                @save-disabled="isEditedExample = false"
                @status-change="handleExampleImagesStatusChange"
              />
            </template>
          </el-table-column>
          <!-- Действия -->
          <el-table-column label="Действия" width="120">
            <template #default="scope">
              <el-button
                :disabled="!isEditExamplesMode"
                size="small"
                type="danger"
                @click="removeExampleRow(scope.$index)"
              >
                Удалить
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </MainPanel>
    </div>
    <div class="contractors-services">
      <MainPanel v-loading="loading">
        <h2>Навыки</h2>
        <div>
          <el-button
            v-for="tag in tags"
            :key="tag.id"
            style="margin: 10px 10px 0 0;"
            type="success"
          >
            {{ tag.title }}
          </el-button>
        </div>
      </MainPanel>
    </div>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel';
import {mapActions} from 'vuex';
import PortfolioVideos from './PortfolioVideos.vue'
import ImagesEdit from '@/components/common/ImagesEdit.vue';
import Redactor from '@/components/common/WysiwygRedactor.vue'

export default {
  name: 'ContractorAdditionalInfo',
  components: { Redactor, MainPanel, PortfolioVideos, ImagesEdit },
  props: {
    contractor: {type: Object, required: true},
  },
  data() {
    return {
      contractorsServices: null,
      portfolioImages: null,
      portfolioVideos: null,
      workExamples: null,
      tags: null,
      adminStatusesLoaded: false,
      loading: false,
      isEditServicesMode: false,
      isEditExamplesMode: false,
      isEditPortfolioMode: false,
      isEditedService: false,
      isEditedExample: false,
      isEditedPortfolio: false,
      portfolioSaveDisabled: false,
    }
  },
  computed: {
    canSaveServices() {
      return this.contractorsServices.some(service =>
        !service.title?.trim() || !service.description?.trim(),
      )
    },
    canSaveExamples() {
      return this.workExamples.some(service =>
        !service.title?.trim(),
      )
    },
  },
  async created() {
    await this.getContractorInfo();
  },
  methods: {
    ...mapActions('contractors', [
      'saveContractorServices',
      'deleteContractorService',
      'saveContractorPortfolio',
      'saveWorkExamples',
      'deleteWorkExample',
    ]),
    async getContractorInfo() {
      this.loading = true;
      this.contractorsServices = this.contractor.contractors_services
      this.portfolioImages = this.contractor.portfolio?.portfolio_images
      this.portfolioVideos = this.contractor.portfolio?.video_links
      this.workExamples = this.contractor.work_examples
      this.tags = this.contractor.tags
      this.loading = false;
    },
    toggleEditServiceMode() {
      this.isEditServicesMode = !this.isEditServicesMode;
    },
    toggleEditExamplesMode() {
      this.isEditExamplesMode = !this.isEditExamplesMode;
    },
    toggleEditPortfolioMode() {
      this.isEditPortfolioMode = !this.isEditPortfolioMode;
    },
    addServicesRow() {
      this.contractorsServices.push({
        title: '',
        description: '',
        price: null,
        currency: 'UAH',
        is_price_from: false,
        is_negotiable: false,
      });
      this.isEditedService = true;
    },
    addExamplesRow() {
      this.workExamples.push({
        title: '',
        price: null,
        currency: 'UAH',
        is_price_from: false,
        is_negotiable: false,
        work_example_images: [],
      });
      this.isEditedExample = true;
    },
    removeServicesRow(index) {
      if ('id' in this.contractorsServices[index]) {
        this.deleteContractorService({id: this.contractorsServices[index].id})
          .then(async () => {
            await this.$parent.getContractor();
            this.contractorsServices = this.contractor.contractors_services;
          });
      } else {
        this.contractorsServices.splice(index, 1);
      }
    },
    removeExampleRow(index) {
      if ('id' in this.workExamples[index]) {
        this.deleteWorkExample({id: this.workExamples[index].id})
          .then(async () => {
            await this.$parent.getContractor();
            this.workExamples = this.contractor.work_examples;
          });
      } else {
        this.workExamples.splice(index, 1);
      }
    },
    saveServices() {
      this.saveContractorServices({id: this.contractor.id, contractorServices: this.contractorsServices})
        .then((rsp) => {
          this.contractorsServices = rsp;

          this.$message({
            message: 'Послуги виконавця успішно збережено!',
            type: 'success',
          });
        })
        .catch(error => {
          console.error('Помилка при оновленні послуг:', error)
          this.$message({
            message: 'Помилка при оновленні послуг: ' + error.message,
            type: 'error',
          })
        })

      this.isEditedService = false;
    },
    saveExamples() {
      this.workExamples = this.workExamples.map(item => ({
        ...item,
        work_example_images: item.work_example_images.map(img => img.id),
      }));
      this.saveWorkExamples({id: this.contractor.id, workExamples: this.workExamples})
        .then((rsp) => {
          this.workExamples = rsp;

          this.$message({
            message: 'Приклади робіт виконавця успішно збережено!',
            type: 'success',
          });
        })
        .catch(error => {
          console.error('Помилка при оновленні прикладів робіт:', error)
          this.$message({
            message: 'Помилка при оновленні прикладів робіт'  + error.message,
            type: 'error',
          })
        })

      this.isEditedExample = false;
    },
    isNegotiableChanged(row) {
      if (row.is_negotiable) {
        row.price = null;
        row.currency = 'UAH';
        row.is_price_from = false;
      }
      if ('work_example_images' in row) {
        this.markAsEditedExample()
      } else {
        this.markAsEditedService()
      }
    },
    markAsEditedService() {
      this.isEditedService = true;
    },
    markAsEditedExample() {
      this.isEditedExample = true;
    },

    disablePortfolioSave() {
      this.portfolioSaveDisabled = true;
    },

    handleImagesStatusChange({ isEdited, images }) {
      this.portfolioSaveDisabled = false;
      if (isEdited) {
        this.isEditedPortfolio = true;
        this.portfolioImages = images;
      }
    },
    handleExampleImagesStatusChange({ isEdited, images, index }) {
      if (isEdited) {
        this.isEditedExample = true;
        this.workExamples[index].work_example_images = images;
      }
    },
    handleVideosStatusChange({ isEdited, videos }) {
      if (isEdited) {
        this.isEditedPortfolio = true;
        this.portfolioVideos = videos;
      }
    },
    savePortfolio() {
      let data = {
        portfolio_images: this.portfolioImages.map(img => img.id),
        portfolio_videos: this.portfolioVideos,
        id: this.contractor.portfolio?.id,
      }

      this.saveContractorPortfolio({id: this.contractor.id, data: data})
        .then((rsp) => {
          this.portfolioImages = rsp.portfolio_images;
          this.portfolioVideos = rsp.video_links;

          this.$message({
            message: 'Портфоліо виконавця успішно збережено!',
            type: 'success',
          });
        })
        .catch(error => {
          console.error('Помилка при оновленні портфоліо:', error)
          this.$message({
            message: 'Помилка при оновленні портфоліо',
            type: 'error',
          })
        })

      this.isEditedPortfolio = false;
      this.toggleEditPortfolioMode()
    },
  },
};
</script>

<style scoped>
.contractors-services {
  margin-bottom: 20px;
}
</style>


