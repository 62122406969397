<template>
  <div v-if="contractor">
    <MainPanel v-loading="loading">
      <h2>Информация о исполнителе</h2>
      <table v-if="contractor" class="user-data" style="width: 100%;">
        <tbody>
          <tr>
            <el-upload
              action=""
              class="avatar-uploader"
              :http-request="onFileSelect"
              :show-file-list="false"
            >
              <img
                v-if="imageUrl"
                alt="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
                class="avatar"
                :src="imageUrl"
              >
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </el-upload>
          </tr>
          <tr>
            <td style="width: 200px;"><strong>Имя</strong></td>
            <td>{{ contractor && contractor.user.show_name }}</td>
          </tr>
          <tr>
            <td><strong>ID</strong></td>
            <td>{{ contractor && contractor.id }}</td>
          </tr>
          <tr>
            <td><strong>Описание</strong></td>
            <td class="editable-text-container">
              <EditableWysiwygText v-if="contractor && contractor.description" ref="editableText" v-model="contractor.description" />
              <i v-if="descriptionChanged" class="editable-text__icon fa fa-check" @click="changeDescription(contractor.description)" />
            </td>
          </tr>
          <tr>
            <td><strong>Дата создания</strong></td>
            <td>{{ contractor && contractor.created_at }}</td>
          </tr>
          <tr>
            <td><strong>Ссылка на сайт</strong></td>
            <td><a :href="contractor.frontend_url" target="_blank">{{ contractor.frontend_url }}</a></td>
          </tr>
          <tr>
            <td><strong>Админ. статус</strong></td>
            <td>
              <el-dropdown
                class="btn-item"
                trigger="click"
                @command="(command) => changeStatus(command)"
              >
                <span class="el-dropdown-link">
                  {{ contractor.admin_status || 'Выберите статус' }}
                  <i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(value, key) in adminStatuses"
                    :key="key"
                    :command="value"
                  >
                    {{ value }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </td>
          </tr>
          <tr>
            <td><strong>Топ статус</strong></td>
            <td>
              <el-switch
                active-color="#13ce66"
                :value="contractor.is_top"
                @change="changeTopStatus"
              />
            </td>
          </tr>
          <tr>
            <td><strong>Мы рекомендуем</strong></td>
            <td>
              <el-switch
                active-color="#13ce66"
                :value="contractor.is_recommended"
                @change="changeRecommendedStatus"
              />
            </td>
          </tr>
          <tr>
            <td><strong>Позиция</strong></td>
            <td>
              <el-input
                v-model="contractor.position"
                v-debounce:700ms="changePosition"
                class="small-input"
                type="number"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </MainPanel>
  </div>
</template>

<script>
import MainPanel from '@/components/common/MainPanel';
import {mapActions, mapGetters} from 'vuex';
import EditableWysiwygText from '@/components/common/EditableWysiwygText.vue'

export default {
  metaInfo: {
    title: 'Исполнитель',
  },
  components: { EditableWysiwygText, MainPanel},
  props: {
    contractor: {type: Object, required: true},
  },
  data() {
    return {
      localAdminStatuses: [{'DEFAULT': 'Без статусу'}],
      descriptionInitValue: '',
      imageUrl: '',
      loading: false,
    }
  },
  computed: {
    ...mapGetters('contractors', [
      'contractorAdminStatusesTransformed',
    ]),
    adminStatuses() {
      return this.localAdminStatuses.reduce((acc, status) => {
        acc[status.key] = status.label;
        return acc;
      }, {});
    },
    descriptionChanged() {
      return this.contractor.description !== this.descriptionInitValue
    },
  },
  async created() {
    await this.loadAdminStatuses()
    this.descriptionInitValue = this.contractor.description;
    this.imageUrl = this.contractor.user.avatar;
  },
  methods: {
    ...mapActions('contractors', [
      'fetchContractorAdminStatuses',
      'saveAdminStatus',
      'saveDescription',
      'saveAvatar',
      'uploadImage',
      'changeContractorTopStatus',
      'changeContractorPosition',
      'changeContractorRecommendStatus',
    ]),

    async loadAdminStatuses() {
      if (!this.adminStatusesLoaded) {
        await this.fetchContractorAdminStatuses()
        this.localAdminStatuses = [
          { key: 'DEFAULT', label: 'Без статусу' },
          ...this.contractorAdminStatusesTransformed,
        ]
        this.adminStatusesLoaded = true
      }
    },

    changeStatus(status) {
      if (status === 'Без статусу') {
        status = null;
      }
      this.saveAdminStatus({id: this.contractor.id, status: status})
        .then(() => {
          this.$set(this.contractor, 'admin_status', status)

          this.$message({
            message: 'Статус успешно обновлен',
            type: 'success',
          })
        })
        .catch(error => {
          console.error('Ошибка при обновлении статуса:', error)
          this.$message({
            message: 'Ошибка при обновлении статуса',
            type: 'error',
          })
        })
    },

    async changeTopStatus(val) {
      this.contractor.is_top = val

      try {
        await this.changeContractorTopStatus({id: this.contractor.id, params: {is_top: val}})
        await this.$message.success('Статус успешно изменен');
      } catch (e) {
        await this.$message.error('Статус изменить не удалось');
        this.contractor.is_top = !val
      }
    },

    async changeRecommendedStatus(val) {
      this.contractor.is_recommended = val

      try {
        await this.changeContractorRecommendStatus({id: this.contractor.id, params: {is_recommended: val}})
        await this.$message.success('Статус успешно изменен');
      } catch (e) {
        await this.$message.error('Статус изменить не удалось');
        this.contractor.is_recommended = !val
      }
    },

    changeDescription(description) {

      this.saveDescription({id: this.contractor.id, description: description})
        .then(() => {
          this.descriptionInitValue = description;

          this.$message({
            message: 'Опис успішно збережено!',
            type: 'success',
          });
          this.$emit('contractor-updated')
        })
        .catch(error => {
          console.error('Помилка при оновленні опису:', error)
          this.$message({
            message: 'Помилка при оновленні опису',
            type: 'error',
          })
        })
      this.$refs.editableText.resetEditing();
    },

    async changePosition(val) {
      const oldValue = val
      this.contractor.position = val

      try {
        await this.changeContractorPosition({id: this.contractor.id, params: {position: val}})
        await this.$message.success('Позиция успешно изменена');
      } catch (e) {
        await this.$message.error('Позицию изменить не удалось');
        this.contractor.position = oldValue
      }
    },
//avatar
    async onFileSelect(res) {
      const file = res.file;
      const formData = new FormData();
      formData.append('file', file, file.name);

      const img = await this.uploadImage(formData);

      this.imageUrl = img.path;

      const avatar = img.id;

      await this.saveAvatar({ id: this.contractor.id, avatar: avatar });
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/_variables"

.user-data
  td
    padding: 10px 10px
    border-bottom: 1px solid #f1f1f1

  tr
    &:last-of-type
      td
        border-bottom: none

.contractor-info
  flex-basis: 100%

.editable-text__icon
  margin-right: 10px
  color: $color-text-accent-customer
  cursor: pointer

  &:hover
    color: #555555

.editable-text
  word-break: break-word

.editable-text-container
  display: flex
  align-items: center
  gap: 8px


.small-input
  max-width: 80px

.avatar-uploader .el-upload
  border: 1px dashed #d9d9d9
  border-radius: 6px
  cursor: pointer
  position: relative
  overflow: hidden

.avatar-uploader .el-upload:hover
  border-color: #409EFF

.avatar-uploader-icon
  font-size: 28px
  color: #8c939d
  width: 178px
  height: 178px
  line-height: 178px
  text-align: center

.avatar
  width: 178px
  height: 178px
  display: block

</style>
