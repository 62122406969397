<template>
  <div class="portfolio-videos">
    <div v-if="editMode" class="video-edit-section">
      <el-input
        v-for="(video, index) in editableVideos"
        :key="`video-${index}`"
        v-model="editableVideos[index]"
        placeholder="Введите ссылку на видео"
        style="margin-bottom: 10px;"
        @input="updateVideosStatus"
      >
        <template #append>
          <el-button
            icon="el-icon-delete"
            type="danger"
            @click="removeVideo(index)"
          />
        </template>
      </el-input>
      <el-button
        v-if="editableVideos.length < 8"
        type="success"
        @click="addVideoField"
      >
        Добавить видео
      </el-button>
    </div>

    <div v-else>
      <div v-if="videos && videos.length > 0">
        <ul>
          <li v-for="(video, index) in videos" :key="index">
            <a :href="video" target="_blank">{{ video }}</a>
          </li>
        </ul>
      </div>
      <div v-else>
        ---
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PortfolioVideos',
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editableVideos: [],
      isEdited: false,
    }
  },
  watch: {
    editMode(newValue) {
      if (newValue) {
        this.prepareVideoEditing();
      }
    },
    videos: {
      immediate: true,
      handler(newVideos) {
        if (!this.editMode) {
          this.editableVideos = [...(newVideos || [])];
        }
      },
    },
  },
  methods: {
    prepareVideoEditing() {
      this.editableVideos = [...(this.videos || [])];
      if (this.editableVideos.length === 0) {
        this.editableVideos.push('');
      }
      this.isEdited = false;
    },
    addVideoField() {
      if (this.editableVideos.length < 8) {
        this.editableVideos.push('');
        this.updateVideosStatus();
      }
    },
    removeVideo(index) {
      this.editableVideos.splice(index, 1);
      this.updateVideosStatus();
    },
    updateVideosStatus() {
      const validVideos = this.editableVideos.filter(video => video.trim() !== '');
      this.isEdited = JSON.stringify(validVideos) !== JSON.stringify(this.videos);

      this.$emit('status-change', {
        isEdited: this.isEdited,
        videos: validVideos,
      });
    },
  },
}
</script>
