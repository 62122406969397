<template>
  <div class="editor__wrapper">
    <vue-editor
      v-bind="$attrs"
      :class="{'editor--disabled': disabled}"
      :editor-toolbar="toolbar"
      :value="value"
      @input="$emit('input', $event)"
    />
    <div v-if="disabled" class="editor__disabled-mask" />
  </div>
</template>

<script>
import {VueEditor} from 'vue2-editor'

export default {
  name: 'Redactor',
  components: {
    VueEditor,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'standard',
    },
  },
  data: () => ({
    toolbarStandard: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, {list: 'check'}],
      [{ header: [1, 2, 3, 4, 5, false] }],
      [{
        color: [],
      },{
        background: [],
      }],
      [{
        align: '',
      }, {
        align: 'center',
      }, {
        align: 'right',
      }, {
        align: 'justify',
      }],
      ['clean'],
    ],
    toolbarWithoutHeadings: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, {list: 'check'}],
      [{
        color: [],
      },{
        background: [],
      }],
      [{
        align: '',
      }, {
        align: 'center',
      }, {
        align: 'right',
      }, {
        align: 'justify',
      }],
      ['clean'],
    ],
  }),
  computed: {
    toolbar() {
      if (this.variant === 'without-headings') {
        return this.toolbarWithoutHeadings
      }

      return this.toolbarStandard
    },
  },
}
</script>

<style lang="sass">

.editor__wrapper
  position: relative

div.ql-editor
  font-size: 14px
  line-height: 1.4

.ql-container
  font-family: 'Montserrat', sans-serif !important

.editor--disabled
  opacity: 0.5
  pointer-events: none
  cursor: not-allowed

.editor__disabled-mask
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  cursor: not-allowed

</style>
