import api from '@/utils/api'

export default {
  state: () => ({
    messengersList: null,
  }),
  mutations: {
    setMessengersList(state, payload) {
      state.messengersList = payload
    },
  },
  getters: {},
  actions: {
    async fetchMessengersList({ commit }) {
      const res = await api.http.get('admin/messengers')
      commit('setMessengersList', res?.data?.data)
      return res?.data?.data
    },

    async createMessenger(_, form) {
      await api.http.post('admin/messengers', form)
    },

    async updateMessenger(_, form) {
      await api.http.post(`admin/messengers/${form.id}/update`, form)
    },

    async deleteMessenger(_, id) {
      await api.http.delete(`admin/messengers/${id}/delete`)
    },

    async getMessenger(_, id) {
      const res = await api.http.get(`admin/messengers/${id}`)
      return res?.data?.data
    },

    async uploadImage(_, formData) {
      const res = await api.http.post('admin/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return res?.data?.data
    },
  },
  namespaced: true,
} 