<template>
  <div>
    <PageHeader page-name="Исполнители">
      <template>
        <FilterPanel>
          <Filters @filter="doFilter" />
        </FilterPanel>
      </template>
    </PageHeader>

    <MainPanel>
      <List
        v-loading="loading"
        @openModal="openModal"
        @refetch="getContractors"
        @sort="doSort"
      />

      <el-pagination
        v-if="meta"
        background
        :current-page="filterObject.page"
        hide-on-single-page
        layout="prev, pager, next"
        :page-size="meta.per_page"
        :total="meta.total"
        @current-change="changePage"
      />
    </MainPanel>
    <ChangePhoneNumberModal
      :id="selectedId"
      :phone="selectedPhone"
      :visible="visibleCheckModal"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import List from '@/components/contractors/List'
import MainPanel from '@/components/common/MainPanel'
import FilterPanel from '@/components/common/FilterPanel'
import Filters from '@/components/contractors/Filters'
import PageHeader from '@/components/common/PageHeader';
import ChangePhoneNumberModal from '../../components/contractors/ChangePhoneNumberModal.vue';


export default {
  components: {ChangePhoneNumberModal, Filters, FilterPanel, MainPanel, List, PageHeader},
  metaInfo: {
    title: 'Исполнители',
  },
  data() {
    return {
      loading: false,
      filterObject: {
        page: 1,
      },
      selectedPhone: '',
      selectedId: '',
      visibleCheckModal: false,
    }
  },
  computed: {
    ...mapState('contractors', [
      'contractors',
    ]),
    meta() {
      return this.contractors?.meta
    },
  },
  mounted() {
    this.getContractors()
  },
  methods: {
    ...mapActions('contractors', [
      'fetchContractors',
    ]),
    async getContractors() {
      this.loading = true

      await this.fetchContractors(this.filterObject)
      this.loading = false
    },
    changePage(page) {
      this.filterObject.page = page
      this.getContractors()
    },
    doFilter(filters) {
      this.filterObject = { ...this.filterObject, ...filters }
      this.filterObject.page = 1
      this.getContractors()
    },
    doSort(sort) {
      this.filterObject = {...this.filterObject, ...sort}
      this.filterObject.page = 1
      this.getContractors()
    },
    openModal({phone, id}) {
      console.log('phone', phone, 'id', id)
      this.selectedPhone = phone?.replace(/ /g, '') || '';
      this.selectedId = id;
      this.visibleCheckModal = true
    },
    closeModal() {
      this.visibleCheckModal = false
    },
  },
}
</script>
