import axios from 'axios'
import {Message} from 'element-ui'
import router from '../router'

class Api {

  token = null
  http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URI,
    headers: {
      'Authorization': `Bearer ${this.getToken()}`,
    },
  })

  constructor() {
    this.setInterceptors()
  }


  async setToken(token) {
    localStorage.setItem('token', token)

    this.http.defaults.headers = {
      'Authorization': 'Bearer ' + token,
    }
  }

  setInterceptors() {
    this.http.interceptors.response.use(
      response => response,
      error => {
        if (error.response) {
          // Handle specific HTTP error status codes

          const message = error.response?.data.message
          switch (error.response.status) {
            case 401:
              if (router.app.$route.name !== 'Login') {
                router.push({name: 'Login'})
              }
              break
            case 403:
              Message.error('У вас нет прав для выполнения этого действия')
              break
            case 404:
              Message.error('Запрашиваемый ресурс не найден')
              break
            case 422:
              this.handleValidationError(error.response.data)
              break
            case 429:
              Message.error('Слишком много запросов. Пожалуйста, подождите')
              break
            case 500:
              Message.error(message || 'Внутренняя ошибка сервера')
              break
            default:
              this.handleGeneralError(error)
          }
        } else if (error.request) {
          // Handle network errors
          Message.error('Ошибка сети. Проверьте подключение к интернету')
        } else {
          // Handle other errors
          Message.error('Произошла ошибка при выполнении запроса')
        }
        return Promise.reject(error)
      },
    )
  }

  handleValidationError(data) {
    let message = ''
    if (data.errors) {
      const errorsArray = Object.values(data.errors)
      message = `${data.message ? data.message + '\n' : ''}${errorsArray.map(i => i.join('\n')).join('\n')}`
    } else {
      message = data.message || 'Ошибка валидации'
    }
    Message.error(message)
  }

  handleGeneralError(error) {
    let message = ''
    if (error.response?.data?.message) {
      message = error.response.data.message
    } else if (error.message) {
      message = error.message
    } else {
      message = 'Произошла ошибка при выполнении запроса'
    }
    Message.error(message)
  }

  clearToken() {
    localStorage.removeItem('token')
    this.http.defaults.headers = {
      'Authorization': null,
    }
  }

  getToken() {
    return localStorage.getItem('token')
  }
}

export default new Api
