<template>
  <el-table :data="list" stripe style="width: 100%">
    <el-table-column label="Вопрос" min-width="300" prop="name" />
    <el-table-column label="Группа" min-width="150" prop="group">
      <template slot-scope="scope">
        {{ getGroupName(scope.row.group) }}
      </template>
    </el-table-column>
    <el-table-column fixed="right" width="120">
      <template slot-scope="scope">
        <el-button
          size="small"
          type="success"
          @click="onEdit(scope.row.id)"
        >
          <i class="far fa-edit" />
        </el-button>

        <el-button
          size="small"
          type="danger"
          @click="onDelete(scope.row.id)"
        >
          <i class="far fa-trash-alt" />
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'List',
  computed: {
    ...mapState('help-center', {
      list: 'FAQList',
    }),
  },
  methods: {
    ...mapActions('help-center', [
      'deleteFAQItem',
      'fetchFAQList',
    ]),
    getGroupName(group) {
      const groupNames = {
        main: 'На главной',
        product: 'На объявлениях',
        contractor: 'На исполнителях',
      };
      return groupNames[group] || group;
    },
    onEdit(id) {
      this.$router.push({name: 'UpdateFAQ', params: {id}})
    },
    async onDelete(id) {
      try {
        const res = await this.$confirm('Вы действительно хотите удалить этот элемент?')
        if (res) {
          await this.deleteFAQItem(id)
          await this.fetchFAQList()
        }
      } catch {
      }
    },
  },
}
</script>
