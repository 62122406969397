import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '@/pages/LoginPage'
import HomePage from '@/pages/HomePage'
import helpCenter from '@/router/help-center'
import adminManagement from '@/router/admin-management'
import users from '@/router/users'
import contractors from '@/router/contractors'
import clients from '@/router/clients';
import cities from '@/router/cities';
import store from '@/store'
import translations from '@/router/translations'
import wishes from '@/router/wishes'
import NotFoundPage from '@/pages/NotFoundPage'
import offers from '@/router/offers'
import complaints from '@/router/complaints'
import {userCan} from '../utils/permissions'
import logs from './logs';
import tickets from '@/router/tickets';
import TreePage from '@/pages/categories/TreePage'
import statistics from './statistics';
import WishesTrash from '@/router/wishes-trash'
import OffersTrash from '@/router/offers-trash'
import internalUsers from '@/router/internal-users'
import internalWishes from '@/router/internal-wishes'
import products from '@/router/products'
import events from '@/router/events'
import topics from '@/router/topics'
import eventsMain from '@/router/events-main'
import categories from '@/router/categories';
import SearchManagement from '@/pages/SearchManagement'
import settings from '@/router/settings'
import socials from '@/router/socials'
import messengers from '@/router/messengers'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      layout: 'auth-layout',
    },
  },
    {
    path: '/search-management',
    name: 'SearchManagement',
    component: SearchManagement,
    meta: {
      requiresAuth: true,
    },
  },
  ...tickets,
  ...logs,
  ...helpCenter,
  ...adminManagement,
  ...users,
  ...contractors,
  ...clients,
  ...cities,
  ...translations,
  ...wishes,
  ...offers,
  ...complaints,
  ...statistics,
  ...WishesTrash,
  ...OffersTrash,
  ...internalUsers,
  ...internalWishes,
  ...products,
  ...events,
  ...topics,
  ...eventsMain,
  ...categories,
  ...settings,
  ...socials,
  ...messengers,
  {
    path: '/categories',
    name: 'CategoriesTree',
    component: TreePage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundPage,
    meta: {
      layout: 'auth-layout',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isGuest']) {
      store.dispatch('auth/loadUser').then(() => {
        if (store.getters['auth/isGuest']) {
          next({
            path: '/login',
          })
        } else {
          next()
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

function isAuthenticated(to) {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isGuest']) {
      store.dispatch('auth/loadUser').then(() => {
        return !store.getters['auth/isGuest']
      })
    } else {
      return true
    }
  } else {
    return true
  }
}

function isPermissionExists(to) {
  if (to.matched.some(record => record.meta.requiresPermissions)) {
    return userCan(store.state.auth.user, to.meta.requiresPermissions)
  } else {
    return true
  }
}

router.beforeEach((to, from, next) => {
  if (isAuthenticated(to)) {
    if (isPermissionExists(to)) {
      next()
    } else {
      next({
        path: '/',
      })
    }
  } else {
    next({
      path: '/login',
    })
  }
})

export default router
