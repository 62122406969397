import api from '@/utils/api';

export default {
  state: () => ({
    eventsList: null,
    eventDetails: null,
    categoriesList: null,
    contractorsList: [],
    productsList: [],
  }),

  mutations: {
    setEventsList(state, payload) {
      state.eventsList = payload;
    },
    setEventDetails(state, payload) {
      state.eventDetails = payload;
    },
    setCategories(state, payload) {
      state.categoriesList = payload;
    },
    setContractorsList(state, payload) {
      state.contractorsList = payload;
    },
    setProductsList(state, payload) {
      state.productsList = payload;
    },
  },

  actions: {
    // Отримання списку подій
    async fetchEventsList({ commit }) {
      const res = await api.http.get('admin/search-events');
      commit('setEventsList', res?.data?.data);
    },
    async fetchCategories({ commit }) {
      // Новий екшен
      try {
        const res = await api.http.get('/tree-categories');
        commit('setCategories', res?.data?.data);
      } catch (error) {
        console.error('Помилка завантаження категорій:', error);
      }
    },

    // Створення нової події
    // async createEvent(_, form) {
    //   await api.http.post('admin/search-events', form);
    // },

    // // Редагування події
    // async updateEvent(_, { id, form }) {
    //   await api.http.post(`admin/search-events/${id}/update`, form);
    // },
    // Створення нової події
    async createEvent(_, form) {
      await api.http.post('admin/search-events', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
    },
    // Редагування події
    async updateEvent(_, { id, form }) {
      if (!id) {
        console.error('Невалідний ID');
        return;
      }

      await api.http.post(`admin/search-events/${id}/update`, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },

    // Отримання інформації про конкретну подію
    async getEventDetails({ commit }, id) {
      if (!id || id === 'undefined') {
        console.error('Невалідний id для події');
        return;
      }
      const res = await api.http.get(`admin/search-events/${id}`);
      commit('setEventDetails', res?.data?.data);
      return res?.data?.data;
    },

    // Видалення події
    async deleteEvent(_, id) {
      await api.http.delete(`admin/search-events/${id}/delete`);
    },

    async searchContractors({ commit }, query) {
      try {
        console.log('query', query)
        const res = await api.http.get('admin/search-events/contractors', {
          params: {
            search: query,
          },
        });
        commit('setContractorsList', res?.data?.data || []);
        return res?.data?.data || [];
      } catch (error) {
        console.error('Error fetching contractors:', error);
        commit('setContractorsList', []);
        return [];
      }
    },

    async searchProducts({ commit }) {
      try {
        const res = await api.http.get('admin/search-events/products');
        commit('setProductsList', res?.data?.data || []);
        return res?.data?.data || [];
      } catch (error) {
        console.error('Error fetching products:', error);
        commit('setProductsList', []);
        return [];
      }
    },
  },

  getters: {
    eventsList: state => state.eventsList,
    eventDetails: state => state.eventDetails,
    categoriesList: state => state.categoriesList,
    contractorsList: state => state.contractorsList,
    productsList: state => state.productsList,
  },

  namespaced: true,
};
