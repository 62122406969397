import SocialsListPage from '@/pages/socials/ListPage'
import CreateUpdateSocialPage from '@/pages/socials/CreateUpdatePage'

export default [
  {
    path: '/socials/list',
    name: 'SocialsList',
    component: SocialsListPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/socials/create',
    name: 'CreateSocial',
    component: CreateUpdateSocialPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/socials/update/:id',
    name: 'UpdateSocial',
    component: CreateUpdateSocialPage,
    meta: {
      requiresAuth: true,
    },
  },
]
