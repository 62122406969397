<template>
  <div class="images">
    <div v-if="editMode" class="image-edit-section">
      <el-upload
        ref="imageUpload"
        action=""
        :file-list="editableImages"
        :http-request="onFileSelect"
        :limit="30"
        list-type="picture-card"
        multiple
        :on-remove="handleRemoveImage"
      >
        <i class="el-icon-plus" />
      </el-upload>
    </div>

    <div v-else>
      <div v-if="images && images.length > 0">
        <img
          v-for="(image, i) in images"
          :key="i"
          alt="image"
          :src="image.path"
          style="max-width: 100px; margin: 5px;"
        >
      </div>
      <div v-else>
        ---
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'ImagesEdit',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData : null,
      editableImages: [],
      selectedFile: null,
      isEdited: false,
    }
  },
  watch: {
    editMode(newValue) {
      if (newValue) {
        this.prepareImageEditing();
      }
    },
  },
  methods: {
    ...mapActions('contractors', ['uploadImage']),
    prepareImageEditing() {
      this.editableImages = this.images.map(image => ({
        name: image.name,
        url: image.path,
        id: image.id,
      }));
      this.isEdited = false;
    },

    async onFileSelect(res) {
      this.$emit('save-disabled');
      if (this.editableImages.length >= 8) {
        this.$message.error('Максимальное количество изображений - 8');
        return;
      }

      try {
        const file = res.file;
        const formData = new FormData();
        formData.append('file', file, file.name);

        const img = await this.uploadImage(formData);
        const fileToAdd = {
          name: file.name,
          url: img.path,
          id: img.id,
        };

        this.editableImages.push(fileToAdd);
        this.updateImagesStatus();
      } catch (error) {
        this.$message.error('Ошибка загрузки изображения');
      }
    },
    handleRemoveImage(file) {
      const index = this.editableImages.findIndex(item => item.id === file.id);
      if (index !== -1) {
        this.editableImages.splice(index, 1);
        this.updateImagesStatus();
      }
    },
    updateImagesStatus() {
      this.isEdited = true;
      this.$emit('status-change', {
        isEdited: this.isEdited,
        images: this.editableImages,
        index: this.index,
      });
    },
  },
}
</script>
