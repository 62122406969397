import api from '@/utils/api'

export default {
  state: () => ({
    aboutSettings: {
      title_one: '',
      title_one_ru: '',
      content_one: '',
      content_one_ru: '',
      title_two: '',
      title_two_ru: '',
      content_two: '',
      content_two_ru: '',
      title_three: '',
      title_three_ru: '',
      content_three: '',
      content_three_ru: '',
    },
  }),

  mutations: {
    setAboutSettings(state, payload) {
      state.aboutSettings = payload
    },
  },

  getters: {},

  actions: {
    async getAboutSettings({ commit }) {
      const res = await api.http.get('admin/about-settings')
      commit('setAboutSettings', res?.data?.data)
    },

    async updateAboutSettings(_, form) {
      await api.http.post('admin/about-settings', form)
    },
  },

  namespaced: true,
}
