<template>
  <div>
    <PageHeader page-name="Мессенджеры">
      <template>
        <router-link :to="{name: 'CreateMessenger'}">
          <el-button type="primary">
            Создать мессенджер
          </el-button>
        </router-link>
      </template>
    </PageHeader>
    <MainPanel>
      <List v-loading="loading" />
    </MainPanel>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import List from '@/components/messengers/List'
import MainPanel from '@/components/common/MainPanel'
import PageHeader from '@/components/common/PageHeader';

export default {
  name: 'MessengersListPage',
  components: {MainPanel, List, PageHeader},
  metaInfo: {
    title: 'Мессенджеры',
  },
  data() {
    return {
      loading: false,
    }
  },
  async mounted() {
    this.loading = true
    await this.getModel()
    this.loading = false
  },
  methods: {
    ...mapActions('messengers', {
      getModel: 'fetchMessengersList',
    }),
  },
}
</script>

<style lang="sass" scoped>
.messengers-list
  padding: 20px

.messengers-list__header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px

.messengers-list__title
  margin: 0
  font-size: 24px
  font-weight: 500

.messengers-list__table
  margin-top: 20px

.messengers-list__image
  max-width: 50px
  max-height: 50px
  object-fit: contain
</style> 